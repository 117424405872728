import React, { useState, useEffect } from "react"
import { isMobile } from "react-device-detect"
import './Video.css'

// Chrome Autoplay Details: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes

const Video = props => {
    let [vidExists, setVidExists] = useState(false)

    useEffect(() => {
        // Controls Autoplay'
        let params = window.location.pathname.split('/').filter(x => x)
        let autoplay = params.filter(x => x === "autoplay")
        let el = document.querySelector(".wowconnect");
        let options = { subtree: true, childList: true }
        let mCallback = ( mutations ) => {
            for (let mutation of mutations) {
                mutation.addedNodes.forEach(node => {
                    if (node.className === "wc-video") {
                        // let player = node.getElementsByTagName('video')[0]
                        // player.onload = player.requestFullscreen()
                        setVidExists(true)
                        if (autoplay.length > 0 || props.autoplay) node.click()
                        observer.disconnect()
                    }
                })
            }
        }
        const observer = new MutationObserver(mCallback)
        observer.observe(el, options)
    }, [props.autoplay])

    useEffect(() => { if (props.isLandscape && vidExists) window.scroll(0,46) }, [props.isLandscape, vidExists])

    return (
        <div className={isMobile ? "player-container-mobile" : "player-container"}>
            <div className={isMobile ? "player-mobile" : "player"}>
            {/* <div className="wowconnect" wc-video={props.file}></div> */}
            <div className="wowconnect" wc-video={ props ? props.file : "" } wc-vast="https://vast.wowyowapi.com/?ti=d982b780-9a67-4838-986a-a8e26307c3a9&vs_tp=linear&ln_skp=skippable&ln_skp_nm=00%3A00%3A06&pbi=c3e92b19-8f6a-40fb-8027-0f1e277e3da3&pti=35e479b0-e7a1-4969-8015-6095029e5a82&cb=[CACHEBUSTING]&dm=[DOMAIN]"></div>
            </div>
            <div className={isMobile ? "video-title-mobile" : "video-title"}>{props.title}</div>
        </div>
    );
}

export default Video