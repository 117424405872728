import React from "react";
import { isMobile } from "react-device-detect"
import "./Details.css"

const Details = ( props ) => {
    return (
        <div className={isMobile ? "Details-container Details-container-mobile" : "Details-container"}>
            <div className={isMobile ? "Details-mobile" : "Details"}>
                <h3>Tags</h3>
                <p>
                {props.tags && props.tags[0].split(", ").map((tag, i) => {
                    return (
                        <span key={i}>{ "#" + tag + " "}</span>
                        // <a key={i} href={`${window.location.origin}/results/${tag}`}>{ "#" + tag + " "}</a>
                    )
                })}
                </p>
                <h3>Description</h3>
                <p>{props.description}</p>
                <h3>Transcript</h3>
                <p>{props.transcript}</p>
            </div>
        </div>
    )
}

export default Details