import React from "react";
import AdSense from 'react-adsense';

// client='ca-pub-1290358753233458'

const GoogleAd = () => {
    return (
        <AdSense.Google
            client='ca-pub-1290358753233458'
            slot='7624275398'
            style={{ display: 'block' }}
            layout='in-article'
            format='fluid'
        />
    )
}

export default GoogleAd