import React, { useState } from "react";
import { isMobile } from "react-device-detect"
import { Form, InputGroup, FormControl, Nav, Alert } from 'react-bootstrap';
import "./Upload.css"

const Upload = ( props ) => {
    let [vidLink, setVidLink] = useState('')
    let [vidSource, setVidSource] = useState('youtube')
    let [resp, setResp] = useState(false)

    
    // https://www.youtube.com/watch?v=LmqbVg9zqjQ&ab_channel=InTheMoney
    let uploadVideo = async () => {
      console.log(vidLink, vidSource)
      if (vidLink) {
        let thirdpartyid = "undefined"
        console.log('Getting 3rd Party ID And Uploading')
        if (vidSource === 'youtube') {
          // https://www.youtube.com/watch?v=iWTtGhz1zLM&ab_channel=TODAY
          let params = vidLink.slice(vidLink.indexOf('?') + 1)
          let tempStr = params.slice(params.indexOf('v') + 2)
          thirdpartyid = tempStr.indexOf('&') ? tempStr.slice(0,tempStr.indexOf('&')) : tempStr
        }
        if (vidSource === 'vimeo') {
          // https://vimeo.com/265045525
          thirdpartyid = vidLink.slice(vidLink.indexOf('.com/') + 5)
        }
        if (vidSource === 'dailymotion') {
        // https://www.dailymotion.com/video/x7xpyi6?playlist=x6lgtp
          thirdpartyid = vidLink.slice(vidLink.indexOf('video/') + 6, vidLink.indexOf('?'))
        }
        if (vidSource === 'facebook') {
        // https://www.facebook.com/794700037/videos/10164716204615038/
          let tempStr = vidLink.slice(vidLink.indexOf('videos/') + 7)
          thirdpartyid = tempStr.slice(0, tempStr.indexOf('/'))
        }
        console.log(thirdpartyid)
        let resp = await (await fetch(`/api/upload?thirdpartyid=${thirdpartyid}&vidsource=${vidSource}`)).json()
        console.log(resp)
        setResp(resp)
      }
    }
  
    return (
      <div style={{ width:'80%' }}>
          {(resp && resp.status === "success") && <Alert style={{ width:'100%' }}  variant="success" onClick={() => setResp(false)} dismissible>
            The video was sucessfully uploaded to the database. The video id is {resp.videoid}
          </Alert>} 
          {(resp && resp.status === "error") && <Alert style={{ width:'100%' }} variant="danger" onClick={() => setResp(false)} dismissible>
            There was an error uploading the video to the database. Error: {resp.err}
          </Alert>}
        <Nav style={{ width:'100%' }}>
          <InputGroup className="mb-3">
            <InputGroup.Append>
              <InputGroup.Text id="basic-addon2">Video Url</InputGroup.Text>
            </InputGroup.Append>
            <FormControl
              onChange={e => setVidLink(e.target.value)}
              placeholder="Please Enter The Video URL"
              aria-label="Please Enter The Video UR"
              aria-describedby="basic-addon2"
            />
            </InputGroup>
            <InputGroup>
            <Form.Control as="select" style={{ width: '150px', fontSize: '1rem' }} className="mr-3" onChange={e => setVidSource(e.target.value.toLowerCase())}>
                <option>Youtube</option>
                <option>Vimeo</option>
                <option>Dailymotion</option>
                <option>Facebook</option>
                {/* <option>Wibbitz</option>
                <option>Jwplayer</option>
                <option>Videojs</option>
                <option>Powr</option>
                <option>Connatix</option>
                <option>Html5</option> */}
            </Form.Control>
            <button type="button" className="btn btn-sm btn-outline-primary w-20 ml-2" onClick={() => uploadVideo()} style={{ width: '150px', fontSize: '1rem' }}>Upload Video</button>
          </InputGroup>
        </Nav>
      </div>
    )
}

export default Upload