import React from "react";
import { isMobile } from "react-device-detect"
import './Footer.css';

const Footer = () => {
    return (
        <div className={ isMobile ? "footer footer-mobile" : "footer"}>
            <div className="footer-primary"></div>
            <div className="footer-post">
                <a className="footer-linkout" href="http://wowyow.com/">
                    Copyright 2020 | All Rights Reserved WowYow, Inc
                </a>
            </div>
        </div>
    );
}

export default Footer