import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect"
import { Form, InputGroup, Nav, Alert, Button, Modal } from 'react-bootstrap';
import "./Manage.css"

const Manage = ( props ) => {
    // Stores all the videos returned from the database
    let [vids, setVids] = useState([])
    
    // For feedback messages including error popups
    let [status, setStatus] =  useState('')
    
    let [vidLink, setVidLink] = useState('')
    let [vidSource, setVidSource] = useState('youtube')
    let [resp, setResp] = useState(false)
    
    // For the Data Show Modal
    let [modalShow, setModalShow] = useState(false)
    let [modalData, setModalData] =  useState({})
    let [modalVideoid, setModalVideoid] =  useState('')
    let [modalTitle, setModalTitle] =  useState('')
    let [modalTitleSearch, setModalTitleSearch] =  useState('')
    let [modalFile, setModalFile] =  useState('')
    let [modalCategories, setModalCategories] =  useState('')
    let [modalDescription, setModalDescription] =  useState('')
    let [modalDescriptionSearch, setModalDescriptionSearch] =  useState('')
    let [modalPoster, setModalPoster] =  useState('')
    let [modalTags, setModalTags] =  useState('')
    let [modalThirdpartyid, setModalThirdpartyid] =  useState('')
    let [modalThirdpartytype, setModalThirdpartytype] =  useState('')
    let [modalTranscript, setModalTranscript] =  useState('')

    // For the Data Show Modal
    let [modalEdit, setModalEdit] = useState(false)
    let [modalEditTitle, setModalEditTitle] =  useState('')
    let [modalEditCategories, setModalEditCategories] =  useState('')
    let [modalEditDescription, setModalEditDescription] =  useState('')
    let [modalEditPoster, setModalEditPoster] =  useState('')
    let [modalEditTags, setModalEditTags] =  useState('')
    let [modalEditTranscript, setModalEditTranscript] =  useState('')

    useEffect(async () => { getVideos() }, [])
    
    const getVideos = async () => {
        let vids = await (await fetch(`/api/manage/getVideos`)).json()
        setVids(vids)
    }
    
    const uploadVideo = async () => {
      if (vidLink) {
        console.log('Getting 3rd Party ID And Uploading')
        let thirdpartyid
        if (vidSource === 'youtube') {
          // ex: https://www.youtube.com/watch?v=iWTtGhz1zLM&ab_channel=TODAY
          let params = vidLink.slice(vidLink.indexOf('?') + 1)
          let tempStr = params.slice(params.indexOf('v') + 2)
          thirdpartyid = tempStr.indexOf('&') ? tempStr.slice(0,tempStr.indexOf('&')) : tempStr
        }
        if (vidSource === 'vimeo') {
          // ex: https://vimeo.com/265045525
          thirdpartyid = vidLink.slice(vidLink.indexOf('.com/') + 5)
        }
        if (vidSource === 'dailymotion') {
          // ex: https://www.dailymotion.com/video/x7xpyi6?playlist=x6lgtp
          thirdpartyid = vidLink.slice(vidLink.indexOf('video/') + 6, vidLink.indexOf('?'))
        }
        if (vidSource === 'facebook') {
          // ex: https://www.facebook.com/794700037/videos/10164716204615038/
          let tempStr = vidLink.slice(vidLink.indexOf('videos/') + 7)
          thirdpartyid = tempStr.slice(0, tempStr.indexOf('/'))
        }
        let resp = await (await fetch(`/api/upload?thirdpartyid=${thirdpartyid}&vidsource=${vidSource}`)).json()
        getVideos()
        setResp(resp)
        setVidLink('')
      }
    }
    
    const getDetails = async ( data ) => {
        console.log("getDetails")
        console.log(data)
        setModalShow(true) 
        setModalData(data)
        setModalVideoid(data.videoid.S)
        setModalTitle(data.title.S)
        setModalTitleSearch(data.title_search.S)
        setModalFile(data.file.S)
        setModalCategories(data.categories.S)
        setModalDescription(data.description.S)
        setModalDescriptionSearch(data.description_search.S)
        setModalPoster(data.poster.S)
        setModalTags(data.tags.S)
        setModalThirdpartyid(data.thirdpartyid.S)
        setModalThirdpartytype(data.thirdpartytype.S)
        setModalTranscript(data.transcript.S)
        setModalEditTitle(data.title.S)
        setModalEditCategories(data.categories.S)
        setModalEditDescription(data.description.S)
        setModalEditPoster(data.poster.S)
        setModalEditTags(data.tags.S)
        setModalEditTranscript(data.transcript.S)
    }
    
    const updateVideo = async ( ) => {
        let newdata = modalData
        newdata.title = { S: modalEditTitle }
        newdata.description = { S: modalEditDescription }
        newdata.poster = { S: modalEditPoster }
        newdata.tags = { S: modalEditTags }
        newdata.transcript = { S: modalEditTranscript }
        newdata.categories = { S: modalEditCategories }
        let resp = await (await fetch('/api/manage/updateVideo', 
                              { method: 'POST', 
                                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'},
                                body: JSON.stringify({data: newdata}) }
                          )).json()
        console.log(resp)
        setModalEdit(false)
        setModalShow(false)
        getVideos()
    }
    
    const editVideoData = async ( ) => {
      
    }
    
    const deleteVideo = async ( id ) => {
        let resp = await (await fetch(`/api/manage/deleteVideo?id=${id}`)).json()
        console.log(resp.status)
        setStatus(resp.status)
        getVideos()
    }
    
    return (
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
              <div>
                  {(resp && resp.status === "success") && <Alert style={{ width:'100%' }}  variant="success" onClick={() => setResp(false)} dismissible>
                    The video was sucessfully uploaded to the database. The video id is {resp.videoid}
                  </Alert>} 
                  {(resp && resp.status === "error") && <Alert style={{ width:'100%' }} variant="danger" onClick={() => setResp(false)} dismissible>
                    There was an error uploading the video to the database. Error: {resp.err}
                  </Alert>}
                <Nav style={{ width:'100%' }}>
                  <InputGroup className="mb-3">
                    <InputGroup.Append className="ml-1">
                      <InputGroup.Text >Video Url</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control
                      className="mr-3"
                      onChange={e => setVidLink(e.target.value)}
                      placeholder="Please Enter The Video URL"
                      aria-label="Please Enter The Video UR"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text >Origin</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control as="select" className="mr-3" onChange={e => setVidSource(e.target.value.toLowerCase())}>
                        <option>Youtube</option>
                        <option>Vimeo</option>
                        <option>Dailymotion</option>
                        <option>Facebook</option>
                        {/* <option>Wibbitz</option>
                        <option>Jwplayer</option>
                        <option>Videojs</option>
                        <option>Powr</option>
                        <option>Connatix</option>
                        <option>Html5</option> */}
                    </Form.Control>
                    <button type="button" className="btn btn-sm btn-outline-primary w-20" onClick={() => uploadVideo()} style={{ width: '150px', fontSize: '1rem' }}>Upload Video</button>
                </InputGroup>
                </Nav>
              </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Video ID</th>
                                    <th>Title</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {vids.map((data, i) => {
                                    return (
                                        <tr key={'dataRow-' + i}>
                                            <td key={'dataItem-' + i + 'videoid'}>{data.videoid.S}</td>
                                            <td key={'dataItem-' + i + 'title'}>{data.title ? data.title.S : ""}</td>
                                            <td><Button variant="primary" onClick={() => getDetails(data) }>Details</Button></td>
                                            <td><Button variant="danger" onClick={() => deleteVideo(data.videoid.S)}>Delete</Button></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>
                  <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                      {modalVideoid}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <table style={{ borderSpacing: '1em' }} className="table table-hover">
                        <tbody>
                          <tr className="mb-2 mt-2">
                            <td>Title</td>
                            <td>{modalTitle}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>File</td>
                            <td>{modalFile}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>Description</td>
                            <td>{modalDescription}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>Categories</td>
                            <td>{modalCategories}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>Poster</td>
                            <td>{modalPoster}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>Tags</td>
                            <td>{modalTags}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>Transcript</td>
                            <td>{modalTranscript}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>Third Party ID</td>
                            <td>{modalThirdpartyid}</td>
                          </tr>
                          <tr className="mb-2 mt-2">
                            <td>Third Party Type</td>
                            <td>{modalThirdpartytype}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={() => {
                        setModalShow(false)
                        setModalEdit(true)
                      }}>Edit</Button>
                      <Button onClick={() => setModalShow(false)}>Close</Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={modalEdit}
                    onHide={() => setModalEdit(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                      {modalVideoid}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <InputGroup className="mb-3">
                        <InputGroup.Append className="ml-1">
                          <InputGroup.Text style={{ width: '160px'}}>Title</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control
                          className="mr-3"
                          onChange={e => setModalEditTitle( e.target.value )}
                          value={modalEditTitle}
                          aria-label="Please Enter The Video Title"
                        />
                      </InputGroup>
  
                       <InputGroup className="mb-3">
                        <InputGroup.Append className="ml-1">
                          <InputGroup.Text style={{ width: '160px'}}>Poster</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control
                          className="mr-3"
                          onChange={e => setModalEditPoster( e.target.value )}
                          value={modalEditPoster}
                          aria-label="Please Enter The Video Poster"
                        />
                      </InputGroup>
                      
                       <InputGroup className="mb-3">
                        <InputGroup.Append className="ml-1">
                          <InputGroup.Text style={{ width: '160px'}}>Categories</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control
                          className="mr-3"
                          onChange={e => setModalEditCategories( e.target.value )}
                          value={modalEditCategories}
                          aria-label="Please Enter The Video Categories"
                        />
                      </InputGroup>
                      

                      
                       <InputGroup className="mb-3">
                        <InputGroup.Append className="ml-1">
                          <InputGroup.Text style={{ width: '160px'}}>Tags</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control
                          className="mr-3"
                          onChange={e => setModalEditTags( e.target.value )}
                          value={modalEditTags}
                          aria-label="Please Enter The Video Tags"
                        />
                      </InputGroup>
                      
                      <InputGroup className="mb-3">
                        <InputGroup.Append className="ml-1">
                          <InputGroup.Text style={{ width: '160px'}}>Description</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control
                          as="textarea"
                          rows={6}
                          className="mr-3"
                          onChange={e => setModalEditDescription( e.target.value )}
                          value={modalEditDescription}
                          aria-label="Please Enter The Video Description"
                        />
                      </InputGroup>
                      
                     <InputGroup className="mb-3">
                        <InputGroup.Append className="ml-1">
                          <InputGroup.Text style={{ width: '160px'}}>Transcript</InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control
                          as="textarea"
                          rows={6}
                          className="mr-3"
                          onChange={e => setModalEditTranscript( e.target.value )}
                          value={modalEditTranscript}
                          aria-label="Please Enter The Video Transcript"
                        />
                      </InputGroup>
                      
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="success" onClick={() => updateVideo()}>Post</Button>
                      <Button onClick={() => {
                        setModalEdit(false)
                        setModalShow(true)
                      }}>Back</Button>
                    </Modal.Footer>
                  </Modal>
             </div>
    )
}

export default Manage