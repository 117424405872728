import React, { useState, useEffect } from 'react'
import { isMobile, withOrientationChange } from "react-device-detect"
import Header from './components/Nav/Header'
import Footer from './components/Nav/Footer'
import HorzAds from './components/Ads/HorzAds'
import AdBox from './components/Ads/AdBox'
import Details from './components/Details/Details'
import Video from './components/Video/Video'
import Results from './components/Results/Results'
import Upload from './components/Upload/Upload'
import Manage from './components/Manage/Manage'
import './App.css';

const VideoSpotlight = props => {
    let [prod, setProd] = useState(false)
    let [autoplay, setAutoPlay] = useState(false)
    let [video, setVideo] = useState(false)
    let [playlist, setPlaylist] = useState(false)

    useEffect(() => {
        // For getting the appropriate video data
        let params = window.location.pathname.split('/').filter(x => x)
        
        let vid = params.filter(x => x !== "video" && x !== "autoplay" && x !== "prod")[0]
        
        if (params.includes("prod")) setProd(true)

        let randVidIds = [
            "5672414b-beeb-407d-c23c-ee2e91ff29ed", "f0190c0a-be7e-4cce-f1e5-be0d45a21f0f", "f421ecfc-64a5-4805-fcc2-a12a70adb19a",
            "3f98683a-689b-4630-ef66-fafddb028033", "303ae18a-4d28-4e45-d9e5-7b4e182ba230", "fd2d426c-a34a-4363-bef9-32eebfbd6377", 
            "31e609d3-b940-478e-f612-135b656a8401", "60b3889c-9e98-4ba3-af0b-69eb5d8e6a6c", "cca2185d-ceaa-4b75-985c-910b09880141"
        ].map(id => ({sort: Math.random(), value: id})).sort((a,b) => a.sort - b.sort).map(a => a.value)
        
        if (vid) randVidIds.unshift(vid)
        
        let getVideo = async ( vid ) => setVideo(await (await fetch(`/api/video?id=${vid}`)).json())

        getVideo(randVidIds[0])
            .then(() => document.getElementById('wy-player-script').src = 'https://d1usx49nhcilp7.cloudfront.net/player/current/player.js')
    }, [])

    return (
        <div className="spotlight">
            {!isMobile && <div className="ad-container"><HorzAds prod={prod} /></div>}
            <div className={isMobile ? "primary-container primary-container-mobile" : "primary-container"}>
                <Video file={video.file} title={video.title} autoplay={autoplay} isLandscape={props.isLandscape} />
            </div>            
            <div className={isMobile ? "secondary-container-mobile" : "secondary-container"}>
                <Details tags={video.tags} description={video.description} transcript={video.transcript}/>
                {!isMobile && <AdBox loaded={video ? true : false} prod={prod} />}
            </div>
            <div className="ad-container"><HorzAds prod={prod} /></div>
        </div>
    );
}


let App = props => {
    let { isLandscape } = props
    let path = window.location.pathname.toLowerCase()
    
    return (
        <div className={isMobile ? "app app-mobile" : "app"}>
            <Header />
            {(path.includes("/video") || path === "/") && <VideoSpotlight isLandscape={isLandscape}/>}
            {(path.includes("/results")) && <Results />}
            {(path.includes("/upload")) && <Upload />}
            {(path.includes("/manage")) && <Manage />}
            <Footer />
        </div>
    );
}

App = withOrientationChange(App)

export default App;