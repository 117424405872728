import React from "react";
import GoogleAd from "./GoogleAd"
import './HorzAds.css';

const HorzAds = ( props ) => {
    if (props.prod) {
        return (
            <div className="horz-ads">
                <GoogleAd />
            </div>
        )
    } else {
        return (
            <div className="horz-ads">
                <b>728 x 90</b>
                {/* <b>970 x 250</b> */}
            </div>
        )
    }
}

export default HorzAds