import React from "react";
import { isMobile } from "react-device-detect"
// import SearchBox from "./SearchBox";
import './Header.css';

export const Header = () => {
    return (
        <nav className={ isMobile ? "header header-mobile" : "header"}>
            <div className="header-logo">
                <a href="/">
                    <div className="header-logo-image"></div>
                </a>
            </div>
            {/* <SearchBox /> */}
        </nav>
    )
}

export default Header