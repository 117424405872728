import React, { useState, useEffect } from "react";
import GoogleAd from './GoogleAd'
import { isMobile } from "react-device-detect"
import './AdBox.css';

const AdRow = ( props ) => {
    if (props.prod) {
        return (
            <div className="ad-box-row">
                <div className="ad-box-row-adsense">
                    <GoogleAd />
                </div>
                { props.winWidth > 1280 && 
                    <div className="ad-box-row-adsense">
                        <GoogleAd />
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div className="ad-box-row">
                <div className="ad-box-row-ad">300 x 250</div>
                { props.winWidth > 1280 && <div className="ad-box-row-ad">300 x 250</div> }
            </div>
        )
    }
}

const AdBox = ( props ) => {
    let [rows, setRows] = useState(0)
    let [winWidth, setWinWidth] = useState(window.innerWidth)

    useEffect(() => {
        if (props.loaded) {
            let adBox = document.getElementsByClassName('Details-container')[0]
            let boxHeight = adBox.clientHeight
            let numRows = Math.floor(boxHeight / 270)
            if (rows !== numRows) setRows(numRows)
        }
    }, [rows, props.loaded])

    useEffect(() => {
        window.onresize = () => setWinWidth(window.innerWidth)
    }, [winWidth])

    return (
        <div className={ winWidth > 1280 ? "ad-box" : "ad-box ad-box-half" }>
            {!isMobile && Array.from(Array(rows).keys()).map(key => {
                return (
                    <AdRow key={key} prod={props.prod} winWidth={winWidth} />
                )
            })}

        </div>
    );
}

export default AdBox