import React, { useState, useEffect } from "react"
import './Results.css'

const Results = () => {
    let [results, setResults] = useState([])
    let [featured, setFeatured] = useState([])
    let searched = window.location.pathname.substr(9)
    
    useEffect(() => {
        let query = window.location.pathname.substr(9)
        let getResults = async (query) => setResults(await (await fetch(`/api/search/results?query=${query}`)).json())
        let getFeatured = async (limit, category) => setFeatured(await (await fetch('/api/featured', {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              limit: limit,
              category: category
            })
          })).json())
        getResults(query).then(() => getFeatured(20, ""))
    }, [])

    const goToVideo = ( id ) => window.location.href = `${window.location.origin}/video/${id}`

    return (
        <div className="results-container">
            <h2 className="results-header">Search Results For { searched }</h2>
            <div className="results">
                {results.map(result => {
                    return (
                        <div className="results-result-new" key={ result.id } onClick={() => goToVideo(result.id) }>
                            <div className="results-thumbnail-new" style={{backgroundImage: "url(" + result.preview.small + ")"}}></div>
                            <div className="results-title-new">{ result.title }</div>
                            <div className="results-title-new">Views:    Published:</div>
                        </div>
                    )
                })}
            </div>
            <h2 className="results-header">Popular Videos In Your Area</h2>
            <div className="results">
                {featured.map(result => {
                    return (
                        <div className="results-result-new" key={ result.id } onClick={() => goToVideo(result.id) }>
                            <div className="results-thumbnail-new" style={{backgroundImage: "url(" + result.preview.small + ")"}}></div>
                            <div className="results-title-new">{ result.title }</div>
                            <div className="results-title-new">Views:    Published:</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default Results